@font-face {
  font-family: 'Noto Sans';
  src: url('../fonts/noto-sans-v9-latin-regular.woff') format('woff'),
    url('../fonts/noto-sans-v9-latin-regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-VariableFont_slnt\,wght.ttf') format('truetype');
}
