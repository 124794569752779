$global-font-color: #515152;
$body-background: #f6f7fc;
$body-font-family: 'Noto Sans';

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: pointer;
  outline: none;
}

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility;
}

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  border-radius: 0;
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  appearance: none;
}
[type='text']:focus,
[type='password']:focus,
[type='date']:focus,
[type='datetime']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='week']:focus,
[type='email']:focus,
[type='number']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='url']:focus,
[type='color']:focus,
textarea:focus {
  outline: none;
}

textarea {
  max-width: 100%;
}
textarea[rows] {
  height: auto;
}

input::placeholder,
textarea::placeholder {
  color: #cacaca;
}

input:disabled,
input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e6e6e6;
  cursor: not-allowed;
}

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0;
}

input[type='search'] {
  box-sizing: border-box;
}

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem;
}

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: .5rem;
  margin-right: 1rem;
  margin-bottom: 0;
}
[type='checkbox'] + label[for],
[type='radio'] + label[for] {
  cursor: pointer;
}

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: .5rem;
}

[type='file'] {
  width: 100%;
}

label {
  display: block;
  margin: 0;
  font-size: .875rem;
  font-weight: normal;
  line-height: 1.8;
  color: #0a0a0a;
}
label.middle {
  margin: 0 0 1rem;
  padding: .5625rem 0;
}
