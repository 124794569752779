.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__quarter-wrapper {
  display: flex;
}

.react-datepicker,
.react-datepicker__current-month,
.react-datepicker__day-name,
.react-datepicker__day-name {
  font-size: 1em;
}

.react-datepicker__header {
  font-size: 1.4rem;
  padding: .7em;
}

.react-datepicker__month {
  margin: .4em 1em;
  min-height: 24.5rem;

  &--selecting-range .react-datepicker__day--in-range {
    background-color: #8fbcd3;
    color: black;
  }

  .react-datepicker__month-text, .react-datepicker__quarter-text {
    padding: 6px 2px;
  }
}

.react-datepicker__day-name, 
.react-datepicker__day {
  line-height: 2.233;
  padding: 0 .166em;
  width: 2.233em;
}

.react-datepicker__day {
  color: black;
  margin: 0;

  &:hover {
    background-color: white;
    color: black;
    font-weight: bolder;
    box-shadow: inset 0 0 0 .166em #73a3bb;
    margin-top: -2px !important;
    border-radius: 0;
    outline: none;
  }

  &--disabled {
    color: #ccc;
    text-decoration: line-through;
  }

  &--keyboard-selected {
    background-color: white;
    box-shadow: inset 0 0 0 .166em #73a3bb;
    margin-top: -2px !important;
    padding: 0;
    border-radius: 0;
    outline: none;
  }

  &--today {
    margin-top: -2px !important;
    padding-bottom: 1px !important;
  }

  &--in-range {
    background-color: #8fbcd3;
    width: 2.233em;
    border-radius: 0;
  }

  &--in-selecting-range {
    background-color: #73a3bb;
    width: 2.233em;
    padding: 0 .166em;
    border-radius: 0;
  }
}

.react-datepicker__navigation--previous {
  left: 1em;
}

.react-datepicker__navigation--next {
  right: 1em;
}
