@import '~loaders.css/src/animations/line-scale-pulse-out';
@import '~react-datepicker/dist/react-datepicker.css';
@import 'fonts';
@import 'settings';
@import 'datepicker';

html {
  box-sizing: border-box;
  font-size: 10px;
  height: 100%;
}

body {
  font-family: $body-font-family;
  background-color: $body-background;
  color: $global-font-color;
  font-size: 1.6rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
  font-weight: 400;
  height: 100%;
  margin: 0;
  padding: 0;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
}

input {
  font-family: $body-font-family;
}

.loading-wrapper {
  align-items: center;
  display: flex;
  height: 5rem;
  justify-content: center;
  width: 5rem;

  .loader .loader-inner {
    display: flex;
    align-items: center;
  }

  // These two classes are required by react-loaders
  .loader-hidden {
    display: none;
  }

  .loader-active {
    display: block;
  }
}

.header {
  padding: 1rem;
  text-align: center;

  &__logo {
    width: 150px;
  }
}
